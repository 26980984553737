import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';

import { Box, Typography, Container, Grid, TextField, InputAdornment, IconButton, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RiGalleryView2, RiStore3Fill } from 'react-icons/ri';
import { Search } from '@material-ui/icons';

import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';

export default function MerchantCategories() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [categories, setCategories] = useState(null);
    const [merchants, setMerchants] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        // Fetch categories and merchants on load
        getUrl('companies')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                addAlert(JSON.stringify(error.message));
            });

        fetchMerchants();
    }, []);

    const fetchMerchants = (query = 'companies') => {
        setLoading(true);
        getUrl(query)
            .then((response) => {
                setMerchants(response.data);
                setLoading(false);
            })
            .catch((error) => {
                addAlert(JSON.stringify(error.message));
                setLoading(false);
            });
    };

    const handleSearch = () => {
        fetchMerchants(`companies?s=${searchKeyword}`);
    };

    const handleCategoryChange = (categoryId) => {
        const query = categoryId === 'all' ? 'companies' : `companies?category_id=${categoryId}`;
        fetchMerchants(query);
    };

    return (
        <Box className={styles.root2} style={{ backgroundImage: 'url(/images/general/bkg/gradient.png)', backgroundSize: 'cover', backgroundRepeat: 'repeat-y', backgroundPosition: 'center center' }}>
            <TitleBar height={70} title={t('item.products')} displayInfo displayCart back pagetitle spendButton />
            <Box className={styles.root}>
                <Container fixed className={styles.breadcrumbRoot}>
                    <div className='p-lr-20 p-b-15'>
                        <TextField
                            variant='filled'
                            value={searchKeyword}
                            fullWidth
                            placeholder={t('home.search')}
                            className={styles.textFieldRoot}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton style={{ padding: 0, width: 30, height: 30, aspectRatio: 1/1 }} onClick={handleSearch}>
                                        <InputAdornment position="end" className='w-full'>
                                            <Search />
                                        </InputAdornment>
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                </Container>

                <Container>
                    <Grid container spacing={2} style={{ alignItems: 'stretch' }}>
                        {merchants.length > 0 ? (
                            merchants.map((merchant) => (
                                <Grid item xs={6} sm={6} key={merchant.id}>
                                    <Link underline='none' to={`/shop/${merchant?.slug}`} component={RouterLink}>
                                        <div className={styles.merchantCard}>
                                            <WebpImg
                                                src={merchant.merchant_company_icon?.file_name || '/images/default-logo.png'}
                                                alt={merchant.name}
                                                className={styles.merchantLogo}
                                            />
                                            <Typography variant="body1" className='txt-center'>{merchant?.[`shop_name_${i18n.language}`]}</Typography>
                                        </div>
                                    </Link>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="body1" align="center">
                                {t('error.merchantNotFound')}
                            </Typography>
                        )}
                    </Grid>
                    {merchants.length > 0 && (
                        <Box textAlign="center" mt={4}>
                            <Typography variant="body2" color="textSecondary">
                                {t('transaction.endOfList')}
                            </Typography>
                        </Box>
                    )}
                </Container>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
    },
    root: {
        padding: '10px 0px',
    },
    root2: {
        background: theme.palette.white.mobileBkg,
        minHeight: '100%',
    },
    textFieldRoot: {
        '& .MuiFilledInput-root': {
            padding: '6px 12px',
            background: '#F6F7F9',
        },
        '& .MuiFilledInput-input': {
            padding: '6px 12px',
        },
    },
    categoryButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '10px 20px',
        borderRadius: '25px',
        marginRight: '10px',
        transition: 'all 0.3s',
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        },
    },
    merchantCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '20px',
        boxShadow: theme.shadows[2],
        borderRadius: '8px',
        transition: 'transform 0.3s, box-shadow 0.3s',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: theme.shadows[5],
        },
    },
    merchantLogo: {
        width: 80,
        height: 80,
        marginBottom: '10px',
        borderRadius: '50%',
        objectFit: 'cover',
        aspectRatio: 1/1,
    },
}));