import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { changeLanguage, changeCurrency as reduxChangeCurrency } from '@actions';
import { LOCAL_URL } from '@configs/Config';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Box, Button, Container, Dialog, Grid, IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { AiOutlineQrcode } from 'react-icons/ai';
import { BiDollar } from "react-icons/bi";
import { FiChevronLeft, FiShoppingBag, FiMaximize, FiHome } from 'react-icons/fi';
import { IoLanguage } from "react-icons/io5";

export default function TitleBar(props) {
    const {
        back = false, backgroundColor = null, hexBkgColor = null, hexTextColor = null, backUrl = null,
        displayCart = false, btnRegister = false, displayUsername = false, currencyButton = true, languageButton = true, displayQr = false , spendButton = false
    } = props;

    const colorTheme = backgroundColor ? 'clwhite' : 'cl-theme';
    const buttonOutlined = backgroundColor ? 'btn-outlined-white' : 'btn-outlined-theme';
    const buttonStyle = backgroundColor ? 'bg-base cl-theme' : 'bg-theme clwhite';
    const borderColor = backgroundColor ? 'border-white' : 'border-theme';

    const theme = useTheme();
    const classes = useStyles();
    const { id, username } = useSelector(state => state.user);
    const { cartTotal, accessToken, currencyDisplay } = useSelector(state => state.general);
    const history = useHistory();
    const shareLink = useRef(null);
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const dispatch = useDispatch();

    // CURRENCY
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeCurrency = (event) => {
        dispatch(reduxChangeCurrency(event.target.value));
    };

    // LANGUAGE
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const langOpen = Boolean(langAnchorEl);
    const handleLangClick = (event) => {
        setLangAnchorEl(event.currentTarget);
    };
    const handleLangClose = () => {
        setLangAnchorEl(null);
    };
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleLangClose();
    }

    // REFERRAL QR
    const [showQr, setShowQr] = useState(false);
    const [qrUrl, setQrUrl] = useState("");
    const showQR = useCallback(() => {
        setQrUrl(`${LOCAL_URL}/register?r=${btoa(id)}`);
        setShowQr(true);
    }, [id]);
    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();
        addAlert(t('profile.copySuccess'), "success");
    }, [id]);

    // EXPIRED DATE
    // const daysLeft = expiry_date => {
    //     var today = new Date();

    //     expiry_date = expiry_date.substring(0, 10);
    //     var expiry = new Date(expiry_date);

    //     if (expiry <= today) return 0;

    //     var diffInTime = expiry.getTime() - today.getTime();
    //     return Math.ceil((diffInTime / (1000 * 3600 * 24)));
    // }

    return (
        <Container className={`${backgroundColor ? `${backgroundColor}` : 'bg-base'} transformHeader flex-m p-tb-10 max-w-full`} style={{ width: 420, maxWidth: '100%', zIndex: 99, height: 75, backdropFilter: 'blur(12px)', background: `${hexBkgColor}` }}>
            <div className='flex-sb-m max-w-full'>
                {/* /////////////////   LEFT    //////////////////// */}
                <div>
                    {back ?
                        <div style={{ width: 'fit-content' , display:'flex',  justifyContent:'center', alignItems:'center' , direction:'row'}}>
                            {backUrl ?
                                <Link underline='none' to={backUrl} className={classes.backIconStyle} component={RouterLink} style={{ zIndex: 3 }} >
                                    <FiChevronLeft className={`${backgroundColor ? 'clwhite' : 'cl-theme'} fs-21`} style={{ color: `${hexTextColor}` }} />
                                </Link>
                                :
                                <div onClick={() => history.goBack()} className={classes.backIconStyle}>
                                    <FiChevronLeft className={`${backgroundColor ? 'clwhite' : 'cl-theme'} fs-21`} style={{ color: `${hexTextColor}` }} />
                                </div>
                            }
                            <Link underline='none' to={"/"} className={classes.backIconStyle} component={RouterLink} style={{ zIndex: 3 , paddingLeft:10}} >
                                <FiHome className={`${backgroundColor ? 'clwhite' : 'cl-theme'} fs-21`} style={{ color: `${hexTextColor}` }} />
                            </Link>
                        </div>
                        :
                        <Link underline='none' to="/home" component={RouterLink} className='flex-c-m'>
                            <img src={`/images/logo/logo-fit${backgroundColor ? '-white' : ''}.png`} alt="logo" style={{ width: 'unset', height: 25 }} />
                            {/* <img src={`/images/logo/logo-fit.png`} alt="logo" style={{ width: 'unset', height: 25 }} /> */}
                        </Link>
                    }
                </div>
                {/* /////////////////   RIGHT    //////////////////// */}
                <div className='flex-r-m' style={{ gap: 10 }}>
                    {languageButton &&
                        <Tooltip title={t('home.language')}>
                            <IconButton
                                onClick={handleLangClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={langOpen}
                                aria-haspopup="true"
                                aria-expanded={langOpen}
                            >
                                <div className={`${buttonStyle} bor50 flex-c-m`} style={{ height: 30, width: 30, boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)', color: `${hexBkgColor}`, background: `${hexTextColor}` }}>
                                    <IoLanguage className="fs-21" />
                                </div>
                            </IconButton>
                        </Tooltip>
                    }
                    <Menu
                        anchorEl={langAnchorEl}
                        id="account-menu"
                        open={langOpen}
                        onClose={handleLangClose}
                        onClick={handleLangClose}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <MenuItem onClick={() => handleChangeLanguage('en')}>English</MenuItem>
                        <MenuItem onClick={() => handleChangeLanguage('cn')}>中文</MenuItem>
                    </Menu>

                    {currencyButton &&
                        <Tooltip title={t('home.currency')}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open}
                                aria-haspopup="true"
                                aria-expanded={open}
                            >
                                <div className={`${buttonStyle} bor50 flex-c-m`} style={{ height: 30, width: 30, boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)', color: `${hexBkgColor}`, background: `${hexTextColor}` }}>
                                    <BiDollar className="fs-21" />
                                </div>
                            </IconButton>
                        </Tooltip>
                    }

                    {
                        spendButton &&
                        <Link underline='none' to="/spend" component={RouterLink}>
                            <Tooltip title={t('title.spend')}>
                                <IconButton
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open}
                                    aria-haspopup="true"
                                    aria-expanded={open}
                                >
                                    <div className={`${buttonStyle} bor50 flex-c-m`} style={{ height: 30, width: 30, boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)', color: `${hexBkgColor}`, background: `${hexTextColor}` }}>
                                        <FiMaximize className="fs-21" />
                                    </div>
                                </IconButton>
                            </Tooltip>
                        </Link>
                    }
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        {_.map(currencyDisplay, (currencyDisplay, key) => {
                            return (<MenuItem key={key} onClick={(event, value) => changeCurrency({ target: { value: currencyDisplay.code } })}>{currencyDisplay.code}</MenuItem>)
                        })}
                    </Menu>

                    {accessToken && displayQr &&
                        <Tooltip title={t('profile.myQrCode')}>
                            <IconButton
                                onClick={showQR}
                                size="small"
                                sx={{ ml: 2 }}
                            >
                                <div className={`${buttonStyle} bor50 flex-c-m`} style={{ height: 30, width: 30, boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)', color: `${hexBkgColor}`, background: `${hexTextColor}` }}>
                                    <AiOutlineQrcode className="fs-21" />
                                </div>
                            </IconButton>
                        </Tooltip>
                    }
                    <Dialog
                        open={showQr}
                        onClose={() => setShowQr(false)}
                        maxWidth="md"
                    >
                        <Box style={{ padding: '20px 0 ' }}>
                            <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3}>
                                <QRCode value={qrUrl} renderAs="svg" fgColor={theme.palette.primary.main} />
                            </Box>
                            <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3} >
                                <Grid container spacing={2}>
                                    <Grid item xs style={{ textAlign: "center" }}>
                                        <form>
                                            <textarea ref={shareLink} value={qrUrl} readOnly style={{ border: 'none', resize: 'none', outline: 'none', backgroundColor: "transparent" }} />
                                        </form>
                                    </Grid>
                                    {document.queryCommandSupported('copy') &&
                                        <Grid item xs style={{ textAlign: "center" }}>
                                            <Button variant="contained" color="secondary" onClick={copyShareLink}>{t('profile.copyLink')}</Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Dialog>

                    {/* {btnRegister && accessToken &&
                        <Link underline='none' to="/recruit" component={RouterLink} className='pointer'>
                            <div className={buttonOutlined}>
                                <p className='txt-center' style={{ width: 'fit-content' }}>{t('button.register')}</p>
                            </div>
                        </Link>
                    } */}

                    {displayCart && accessToken &&
                        <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 15 }}>
                            <div className={`${buttonStyle} flex-c-m p-tb-3 p-lr-20 bor50`} style={{ boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)', color: `${hexBkgColor}`, background: `${hexTextColor}` }}>
                                <p className='p-r-10 fs-16'><b>{cartTotal}</b></p>
                                <FiShoppingBag className='fs-18' />
                            </div>
                        </Link>
                    }

                    {displayUsername && accessToken &&
                        <>
                            <div className={` ${borderColor} p-all-3 bor50 flex-c-m`} style={{ border: '1px solid', width: 35, height: 35, borderColor: backgroundColor ? `${hexTextColor}` : `${hexBkgColor}` }}>
                                <div className={`${buttonStyle} flex-c-m bor50 h-full w-full`}>
                                    <Link underline='none' component={RouterLink} to="/settings" className="flex-c-m">
                                        <p className="txt-upper"><b>{_.size(username) > 0 ? username[0] : '-'}</b></p>
                                    </Link>
                                </div>
                            </div>
                            <Link underline='none' component={RouterLink} to="/settings" className="flex-c-m">
                                <p className={`${colorTheme} fs-14`} style={{ color: `${hexTextColor}` }}>{username}</p>
                            </Link>
                        </>
                    }

                    {!accessToken &&
                        <Link underline='none' to="/login" component={RouterLink}>
                            <div className={buttonOutlined}>
                                <p className='txt-center' style={{ width: 'fit-content' }}>{t('button.login')}</p>
                            </div>
                        </Link>
                    }

                </div>
            </div>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    backIconStyle: {
        cursor: 'pointer',
    },
}));