import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function ShopInfoCard(props) {
    const { shopInfo, backgroundColor='transparent', visitButton=false } = props;
    const styles = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Grid container className='flex-sb-m p-lr-10 p-tb-5' style={{ background: backgroundColor }}>
            <Grid item xs={2}>
                <img className='bor30' style={{ objectFit: 'cover', width: '100%', aspectRatio: '1/1' }} src={shopInfo?.merchant_company_icon?.file_name || "/images/empty/shop-nologo2.png"} alt={`${shopInfo?.[`shop_name_${i18n.language}`]}`} />
            </Grid>
            <Grid item xs={visitButton ? 6 : 10} className='p-l-15 p-r-5'>
                <Typography color="primary" style={{ lineHeight: 1.0 }}><b>{shopInfo?.[`shop_name_${i18n.language}`] || '-'}</b></Typography>
                <Box className='flex-l'>
                    <Typography className={styles.shopInfoTitleStyle} style={{ color: '#8c8c8c', lineHeight: 1.0 }}>
                        {
                            _.size(shopInfo) > 0
                                ? _.size(shopInfo?.shop_average_review?.positive_score) > 0
                                    ? shopInfo?.shop_average_review?.positive_score
                                    : 0
                                : 0
                        }% {t('shop.shopRating')}
                        &nbsp;|&nbsp;{t('shop.joined')} {shopInfo?.joined_day > 31 ? shopInfo?.joined_month : shopInfo?.joined_day}
                        &nbsp;{shopInfo?.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}
                    </Typography>
                </Box>
            </Grid>
            {
                visitButton &&
                <Grid item xs={4} className='flex-r'>
                    <Button variant='contained' style={{ marginTop: 0, padding: '6px 12px' }}>
                        <span className='fs-12'>{t('item.viewShop')}</span>
                    </Button>
                </Grid>
            }
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    shopInfoTitleStyle: {
        fontWeight: 'bold',
        fontSize: 10,
        paddingTop: 5,
    },
}));