import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { Drawer, IconButton, Typography, Grid, Button, FormControl, Input, InputAdornment, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { RiCloseFill } from "react-icons/ri";
import { Remove, Add } from '@material-ui/icons';

import useNotificationLoading from '@helper/useNotificationLoading';
import { useQuery, currencyFormat } from '@helper/Tools';
import { getUrl, postUrl } from '@helper/ApiAction';
import { updateCartTotal, updateShareLinkRef, updateShareLinkProduct } from '@actions';
import { PROJECT_TYPE } from '@configs/Config';

export default function ProductOption(props) {
    const { id, toggleDrawer, open, product, state, setState, page, setPage, warehouse, setWarehouse } = props;
    
    const theme = useTheme();

    let changingColor = product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color;

    const classes = useStyles({ changingColor });
    const dispatch = useDispatch();
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, currency, currencyRate } = useSelector(state => state.general);

    // let priceModDisplay = 'price_mod';

    // ---------- Functions -------------
    const changeQuantity = (condition) => {
        let newQuantity = state.quantity;
        if (condition === 'deduct') {
            newQuantity = newQuantity === 1 ? 1 : parseInt(newQuantity) - 1;
        } else {
            newQuantity = parseInt(newQuantity) + 1;
        }
        setState({ ...state, quantity: newQuantity });
    }

    const onButtonClick = (condition) => {
        const { variant, warehouseId, quantity, product_options, multiPricingId } = state;
        let _multiPricingId = multiPricingId;
        if (product_options) {
            // if (!variant) {
            //     return setState({ ...state, open: true, error: true, message: t('item.selectVariantAddCart') });
            // } 
            // else if (!warehouseId) {
            //     return setState({ ...state, open: true, error: true, message: t('item.selectWarehouseAddCart') });
            // }
        }

        if (quantity <= 0) {
            return setState({ ...state, open: true, error: true, message: t('item.selectQuantityAddCart') });
        }

        if (_.size(product?.member_multi_pricings) > 0) {
            let productOption = _.find(product?.options, function (o) { return o.id === state?.variant; });
            let optionMultiPricing = [];
            if (_.size(product?.member_multi_pricings) > 0) {
                optionMultiPricing = productOption ? _.filter(product?.member_multi_pricings, function (mp) { return _.find(productOption?.multi_pricing_id, function (id) { return parseInt(id) === parseInt(mp.id); }); }) : product?.member_multi_pricings;
            }
            if (_.size(optionMultiPricing) > 0) {
                if(!_multiPricingId){
                    return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
                }
                if (product_options && variant) {
                    let available = false;
                    // let starter = (user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? true : false) : true;
                    // let walletType = (_.find(product?.member_multi_pricings, {id: parseInt(multiPricingId)})).type;
                    _.map(product.options, (option, key) => {
                        if (variant === option.id) {
                            if (_.size(option.multi_pricing_id) > 0) {
                                _.map(option.multi_pricing_id, id => {
                                    if (parseInt(_multiPricingId) === parseInt(id)) {
                                        available = true;
                                        // if((product.type === 'rank' && starter && walletType !== 'starter') ||
                                        // (product.type === 'rank' && !starter && walletType !== 'repurchase') ||
                                        // (product.type === 'normal' && walletType !== 'normal')){
                                        //     available = false;
                                        // }
                                    }
                                })
                            } else {
                                _multiPricingId = 0;
                                available = true;
                            }
                        }
                    })
                    if (!available) {
                        return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
                    }
                }
            }
        }

        let apiData = {
            product_id: id,
            product_warehouse_id: warehouseId,
            quantity,
            multi_pricing_id: _multiPricingId,
            buyNow: condition === 'buynow' ? true : false,
        };

        setLoading(true);
        if (condition === 'buynow') {
            if (accessToken) {
                postUrl(`carts`, apiData).then(response => {
                    if (response.status === 1) {
                        toggleDrawer(false);
                        history.push(`/checkout?${btoa('buynow')}`);
                    } else {
                        setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                // setState({ ...state, open: true, error: true, message: t('item.loginFirst') });
                postUrl(`carts`, apiData).then(response => {
                    if (response.status === 1) {
                        if (encoded_ref_id) {
                            toggleDrawer(false);
                            dispatch(updateShareLinkProduct(""));
                            addAlert(response.data.message, 'success');
                        }
                    } else {
                        setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                    }
                }).then(() => history.push('/cart')).catch((error) => {
                    setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
                }).finally(() => {
                    setLoading(false);
                });
            }
        } else {
            postUrl(`carts`, apiData).then(response => {
                if (response.status === 1) {
                    getUrl(`carts`).then(cartList => {
                        let cartTotal = 0;
                        _.map(cartList.data, cartItem => {
                            _.map(cartItem.items, cartDetails => {
                                cartTotal += parseInt(_.size(cartDetails));
                            })
                        })
                        dispatch(updateCartTotal(cartTotal));
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                    toggleDrawer(false);
                    setState({ ...state, open: true, error: false, message: t('item.addedToCart'), warehouseId: 0, variant: 0, quantity: 1, wid: 0 });
                    setWarehouse([]);

                    if (encoded_ref_id) {
                        dispatch(updateShareLinkProduct(""));
                    }

                    if (response.message) {
                        addAlert(response.message, 'success');
                    }
                } else {
                    setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                }
            }).catch((error) => {
                setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleNextToPayment = () => {
        if(state.product_options){
            if(!state.variant){
                return setState({ ...state, open: true, error: true, message: t('item.selectVariantAddCart') });
            }
        }
        setPage(1);
    }

    // ---------- Layout Design ----------
    const productOptions = () => {
        return (
            <>
                {
                    _.size(product?.options) > 0 &&
                    <>
                        <Typography variant='body2' className='p-t-20 p-b-10'><b>{t('product.variants')}</b></Typography>
                        <div style={{ height: '35%', overflowY: 'auto' }}>
                            {
                                _.size(product?.options) > 0
                                    ? _.map(product?.options, (detail) => {
                                        return (
                                            <Button
                                                fullWidth
                                                key={detail?.id}
                                                className={
                                                    detail?.pricing?.[0]?.quantity > 0
                                                        ? state?.variant === detail?.id ? classes.productOptionSelectedDesign : classes.productOptionDesign
                                                        : classes.productOptionUnavailableDesign
                                                }
                                                onClick={() => {
                                                    setWarehouse(warehouse => (detail?.pricing));

                                                    if (_.size(detail?.pricing) === 1) {
                                                        setState({ ...state, product_options: true, variant: (detail?.pricing?.[0]?.quantity > 0 ? detail?.id : 0), warehouseId: detail?.pricing?.[0]?.id });
                                                        // if (parseFloat(detail?.pricing?.[0]?.[priceModDisplay]) > 0) {
                                                        //     setSellPrice((parseFloat(detail?.pricing?.[0]?.[priceModDisplay])).toFixed(2));
                                                        // }
                                                        // if (parseFloat(detail?.pricing?.[0]?.retail_price) > 0) {
                                                        //     setRetailPrice(detail?.pricing?.[0]?.retail_price);
                                                        // }
                                                    } else {
                                                        // setRetailPrice(product?.retail_price);
                                                        setState(state => ({ ...state, product_options: true, variant: (detail?.pricing?.[0]?.quantity > 0 ? detail?.id : 0), warehouseId: 0 }));
                                                    }

                                                    if (_.size(detail?.pricing) > 0) {
                                                        _.map(detail?.pricing, warehouseOption => {
                                                            if (warehouseOption?.warehouse_id === state?.wid) {
                                                                setState({ ...state, product_options: true, variant: (detail?.pricing?.[0]?.quantity > 0 ? detail?.id : 0), warehouseId: warehouseOption?.id });
                                                                // if (parseFloat(warehouseOption?.[priceModDisplay]) > 0) {
                                                                //     setSellPrice((parseFloat(warehouseOption?.[priceModDisplay])).toFixed(2));
                                                                // }
                                                                // if (parseFloat(warehouseOption?.retail_price) > 0) {
                                                                //     setRetailPrice(warehouseOption?.retail_price);
                                                                // }
                                                            }
                                                        })
                                                    }
                                                }}
                                                style={{ background: state?.variant === detail?.id ? (!product?.display_code?.default ? product?.display_code?.background_color : '#18267C') : null }}
                                            >
                                                <div
                                                    className={classes.optionImage}
                                                    style={{
                                                        opacity: detail?.pricing?.[0]?.quantity > 0 ? '100%' : '50%',
                                                        backgroundImage: _.size(detail?.image) > 0 ? "url("+detail?.image+")" : (state?.variant === detail?.id ? "url('/images/empty/no-image.png')" : "url('/images/empty/no-image-theme.png')"),
                                                    }}
                                                />
                                                <Typography variant='body2' style={{ opacity: detail?.pricing?.[0]?.quantity > 0 ? '100%' : '50%', color: state?.variant === detail?.id ? '#f0f0f0' : null }} className='txt-title'>
                                                    {(product.attribute_name && detail?.attribute_value) ? (i18n.language === 'cn' ? (product?.attribute_name_cn + "：") : (product?.attribute_name_en + ': ')) : ''}
                                                    {detail?.attribute_value ? (i18n.language === 'cn' ? detail?.attribute_value_cn : detail?.attribute_value_en) : ''}
                                                    {detail?.attribute_value && detail?.color && (i18n.language === 'cn' ? "，" : ", ")}
                                                    {detail?.color && t('product.color')}{i18n.language === 'cn' ? detail?.color_cn : detail?.color_en}
                                                    {((detail?.attribute_value || detail?.color) && detail?.size) && (i18n.language === 'cn' ? "，" : ", ")}
                                                    {detail?.size && t('product.size')}{i18n.language === 'cn' ? detail?.size_cn : detail?.size_en}
                                                </Typography>
                                            </Button>
                                        )
                                    })
                                    : null
                                    // : <div className={classes.productOptionSelectedDesign} style={{ background: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>
                                    //     <img
                                    //         src={_.size(product?.images_array) > 0 ? product?.images_array?.[0] : '/images/empty/no-image.png'}
                                    //         style={{ opacity: product?.quantity > 0 ? '100%' : '50%', width: 50, aspectRatio: 1 / 1, objectFit: 'cover', borderRadius: 2, paddingRight: 10 }}
                                    //     />
                                    //     <Typography variant='body2' style={{ opacity: product?.quantity > 0 ? '100%' : '50%', color: '#f0f0f0' }}>Normal Package (Per Pax)</Typography>
                                    // </div>
                            }
                        </div>
                    </>

                }

                <Grid container spacing={2} className='flex-m p-t-30 p-b-15'>
                    <Grid item xs={6}>
                        <Typography variant="body2"><b>{t('product.quantity')}</b></Typography>
                    </Grid>

                    <Grid item xs={6} className='flex-c'>
                        <FormControl>
                            <Input
                                type="text"
                                value={state.quantity}
                                disableUnderline={true}
                                fullWidth={true}
                                classes={{ input: classes.inputStyle }}
                                onChange={({ target }) => {
                                    const newQuantity = parseInt(target.value) ? parseInt(target.value) : 1;
                                    setState({ ...state, quantity: newQuantity });
                                }}
                                startAdornment={
                                    <InputAdornment position="start" style={{ position: 'absolute', left: -40 }}>
                                        <IconButton
                                            className={classes.buttonQuantityDesign}
                                            style={{ backgroundColor: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}
                                            onClick={() => changeQuantity('deduct')}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end" style={{ position: 'absolute', right: -40 }}>
                                        <IconButton
                                            className={classes.buttonQuantityDesign}
                                            style={{ backgroundColor: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}
                                            onClick={() => changeQuantity('add')}
                                        >
                                            <Add />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Button fullWidth variant='contained' onClick={() => handleNextToPayment()} className={classes.buttonContainedDisplayDesign} style={{ background: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>
                    <Typography variant='body1' className='txt-title' style={{ fontWeight: 'bold', color: '#f0f0f0' }}><b>{t('item.next')}</b></Typography>
                </Button>
            </>
        )
    }

    const paymentMethod = () => {
        let productOption = _.find(product?.options, function (o) { return o.id === state?.variant; });
        let optionMultiPricing = [];
        if(_.size(product?.member_multi_pricings) > 0){
            optionMultiPricing = productOption ? _.filter(product?.member_multi_pricings, function(mp){return _.find(productOption?.multi_pricing_id, function(id){return parseInt(id) === parseInt(mp.id);}); }) : product?.member_multi_pricings;
        }
        return (
            <>
                <Typography variant='body2' className='p-t-20 p-b-10'><b>{t('checkout.paymentMethod')}</b></Typography>

                <div style={{ height: '50%', overflowY: 'auto' }}>
                    {
                        _.size(optionMultiPricing) > 0
                            ? _.map(optionMultiPricing, (detail) => {
                                return (
                                    <Button
                                        fullWidth
                                        key={detail?.id}
                                        className={state?.multiPricingId === detail?.id ? classes.productOptionSelectedDesign : classes.productOptionDesign}
                                        style={{ 
                                            background: state?.multiPricingId === detail?.id ? (!product?.display_code?.default ? product?.display_code?.background_color : '#18267C') : null,
                                        }}
                                        onClick={() => { setState({ ...state, multiPricingId: detail?.id }); }} 
                                    >
                                        <Typography variant='body2' style={{ color: state?.multiPricingId === detail?.id ? '#f0f0f0' : null }} className='txt-title'>
                                            {
                                                _.map(detail?.wallet_settings, (setting, k) => (
                                                    setting?.pricing_name + (_.size(detail?.wallet_settings) - 1 > k ? ' + ' : '')
                                                ))
                                            }
                                        </Typography>
                                    </Button>
                                )
                            })
                            : <Button
                                fullWidth
                                className={classes.productOptionSelectedDesign}
                                style={{ background: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}
                                onClick={() => { setState({ ...state, multiPricingId: 0 }); }}
                            >
                                <Typography variant='body2' style={{ color: '#f0f0f0' }} className='txt-title'>
                                    {t('product.onlineBanking')}
                                </Typography>
                            </Button>
                    }
                </div>
                
                <div className='flex-c-m'>
                    <Button variant='outlined' fullWidth onClick={() => onButtonClick("buynow")} className={classes.buttonOutlinedDisplayDesign} style={{ marginRight: 15, border: `2px solid ${!product?.display_code?.default ? product?.display_code?.background_color : '#18267C'}` }}>
                        <Typography variant='body1' className='txt-title' style={{ fontWeight: 'bold', color: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>{t('item.buyNow')}</Typography>
                    </Button>

                    {
                        !product?.direct_payment ?
                        <Button variant='contained' fullWidth onClick={() => onButtonClick("add")} className={classes.buttonContainedDisplayDesign} style={{ background: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C' }}>
                            <Typography variant='body1' className='txt-title' style={{ fontWeight: 'bold', color: '#f0f0f0' }}>{t('item.addToCart')}</Typography>
                        </Button>
                        : null
                    }
                    
                </div>
            </>
        )
    }

    const priceDisplay = () => {
        let sellPrice = parseFloat(product?.sell_price);
        let retailPrice = parseFloat(product?.retail_price);
        if (_.size(product?.options) > 0) {
            let option = _.find(product?.options, function (o) { return o.id === state?.variant; });
            if (option?.pricing?.[0]?.price_mod > 0) {
                sellPrice = parseFloat(option?.pricing?.[0]?.price_mod);
            }
            if (option?.pricing?.[0]?.retail_price > 0) {
                retailPrice = parseFloat(option?.pricing?.[0]?.retail_price);
            }
        }
        return (
            <div className='p-l-10'>
                <p className='fs-21 p-r-10 lh-12' style={{ color: '#4E4E4E' }}>
                    <b>
                        {currency}
                        {currencyFormat(sellPrice * currencyRate[`${currency}`] * state?.quantity)}
                    </b>
                </p>
                {
                    retailPrice > sellPrice &&
                    <p className='fs-14 lh-12' style={{ textDecoration: 'line-through', color: '#ADADAD' }} >
                        {currency}
                        {currencyFormat(retailPrice * currencyRate[`${currency}`] * state?.quantity)}
                    </p>
                }
            </div>
        )
    }

    return (
        <Drawer
            anchor='bottom'
            open={open}
            onClose={() => toggleDrawer(false)}
            PaperProps={{
                style: {
                    borderRadius: '30px 30px 0px 0px',
                    // height: '65%',
                    boxShadow: 'none',
                },
            }}
        >
            <div className='p-tb-20 p-lr-25 s-full'>
                <div className='flex-sb'>
                    <div className='flex-m'>
                        <div style={{ backgroundColor: !product?.display_code?.default ? product?.display_code?.background_color : '#18267C', borderRadius:10, lineHeight: 0 }}>
                            <img
                                src={_.size(product?.images_array) > 0 ? product?.images_array?.[0] : '/images/empty/no-image.png'}
                                className={classes.productImageDesign} alt="Product"
                            />
                        </div>
                        {priceDisplay()}
                    </div>

                    <div>
                        <IconButton onClick={() => toggleDrawer(false)} style={{ padding: 0 }}>
                            <RiCloseFill />
                        </IconButton>
                    </div>
                </div>
                
                { page === 0 ? productOptions() : paymentMethod() }

                {
                    state?.open ?
                        <Snackbar
                            open={state?.open}
                            autoHideDuration={3000}
                            onClose={() => setState({ ...state, open: false, error: false })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Alert elevation={6} variant="filled" severity={state?.error ? 'error' : 'success'}>
                                {state?.message}
                            </Alert>
                        </Snackbar>
                        : null
                }
            </div>
        </Drawer>
    )
}

const useStyles = makeStyles(theme => ({
    productImageDesign: {
        width:80,
        height:80,
        objectFit:'cover',
        borderRadius:10,
    },
    productOptionDesign: (props) => ({
        cursor: 'pointer',
        padding: 10,
        height: 50,
        borderRadius: 10,
        boxShadow: '2px 2px 5px 0px #FFFFFFA6, 2px 2px 4px 0px #0000000F inset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 10,
        background: '#EBEBEB',
        '&:hover': {
            background: props?.changingColor || '#18267C',
            color: '#f0f0f0',
        },
    }),
    productOptionSelectedDesign: {
        cursor: 'pointer',
        padding: 10,
        height: 50,
        borderRadius: 10,
        boxShadow: '2px 2px 5px 0px #FFFFFFA6, 2px 2px 4px 0px #0000000F inset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 10,
    },
    productOptionUnavailableDesign: {
        cursor: 'not-allowed',
        background: '#EBEBEB',
        padding: 10,
        height: 50,
        borderRadius: 10,
        boxShadow: '2px 2px 5px 0px #FFFFFFA6, 2px 2px 4px 0px #0000000F inset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 10,
    },
    inputStyle: {
        textAlign: 'center',
        width: 100,
        color: '#000000',
        background: '#EBEBEB',
        fontSize: 18,
        fontWeight: 'bold',
        boxShadow: '2px 2px 4px 0px #0000001A inset, 2px 2px 4px 0px #FFFFFF',
    },
    buttonQuantityDesign: {
        color: '#f0f0f0', 
        borderRadius: 49,
        boxShadow: '1px 1px 1px 0px #0000001A, 1px 1px 2px 0px #FFFFFFAD inset',
    },
    buttonOutlinedDisplayDesign: {
        marginTop: 30,
        borderRadius: 49,
        padding: '10px 16px',
    },
    buttonContainedDisplayDesign: {
        marginTop: 30,
        boxShadow: '3px 3px 8px 0px #FFFFFF40 inset, -3px -3px 8px 0px #0000002E inset',
        borderRadius: 49,
        padding: '10px 16px',
    },
    optionImage: {
        opacity: '100%',
        width: 40,
        aspectRatio: '1 / 1',
        objectFit: 'cover',
        borderRadius: 2,
        marginRight: 10,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }
}));