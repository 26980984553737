import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { CircularProgress, Grid, Typography, Box, Card, CardContent, Container, Paper, Tabs, Tab, Select, MenuItem, IconButton, Button, CardHeader, CardMedia, Tooltip, List, ListItemText, ListItemIcon, ListItem, Divider, Hidden, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { getUrl } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import NumberFormat from 'react-number-format';
import useNotificationLoading from '../../helper/useNotificationLoading';

//ICON
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { List as ListIcon, ChevronRight, ChevronLeft } from 'react-feather';

//COMPONENTS & LAYOUTS
import Slider from "react-slick";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import PropTypes from 'prop-types';

import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';
import CollectionItemCard from '@components/CollectionItemCard';
import ProductItemCard from '@components/ProductItemCard';
import ShopInfoCard from '@components/ShopInfoCard';

//CSS
import 'react-awesome-slider/dist/styles.css';
import '@css/styles.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Shop() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    let query = useQuery();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { currency, currencyDisplay } = useSelector(state => state.general);
    let { shopSlug } = useParams();

    const pageNumber = query.get("page");
    const catId = query.get("cat");
    const collectionId = query.get("collection");
    const sortBy = query.get("sort");

    const [state, setState] = useState({ tabIndex: 0 });
    const [shopInfo, setShopInfo] = useState({});
    const [shopCategory, setShopCategory] = useState({});
    const [shopCategoryProduct, setShopCategoryProduct] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [reviewPage, setReviewPage] = useState(1);
    const [reviewTotalPage, setReviewTotalPage] = useState(1);

    // Variables
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // Api
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/info`)
            .then(returnShopInfo => {
                setLoading(false);
                if (isMountedRef.current && returnShopInfo.status === 1) {
                    setShopInfo(returnShopInfo.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        if (collectionId && _.size(shopInfo.collections) > 0) {
            const selectedCollection = _.findKey(shopInfo.collections, { id: parseInt(collectionId) });
            if (selectedCollection) {
                setState({ ...state, tabIndex: parseInt(selectedCollection) + 2 });
            }
        }
        // eslint-disable-next-line
    }, [collectionId, shopInfo.collections])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/category`)
            .then(returnShopCategory => {
                setLoading(false);
                if (isMountedRef.current && returnShopCategory.status === 1) {
                    setShopCategory(returnShopCategory.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/products?cat=${catId ? catId : 'all'}&page=${pageNumber ? pageNumber : 1}&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`)
            .then(returnShopProduct => {
                setLoading(false);
                if (isMountedRef.current && returnShopProduct.status === 1) {
                    let { current_page, last_page } = returnShopProduct.data.meta;
                    setShopCategoryProduct(returnShopProduct.data.data);
                    setTotalPage(last_page);
                    setPage(current_page ? current_page : 1);

                    // if (_.size(returnShopProduct.currency_data) > 0) {
                    //     console.log("returnShopProduct.currency_data", returnShopProduct.currency_data);
                    //     setBaseCurrencyDisplay(returnShopProduct.currency_data[`currency_display_${i18n.language}`]);
                    // }
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, catId, pageNumber, addAlert, setLoading, collectionId, sortBy])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`shops/${shopSlug}/review?sort=${reviewSort}&page=${reviewPage}`)
            .then(reviewData => {
                // console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setReviewTotalPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [shopSlug, reviewSort, reviewPage, setLoading, addAlert])

    // Functions
    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });
        if (newValue === 0) {
            history.push(`/shop/${shopSlug}`)
        } else if (newValue === 1) {
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=&sort=${sortBy ? sortBy : 'popular'}`)
        } else if (newValue !== 999 && newValue > 1 && _.size(shopInfo.collections) > 0) {
            // collection page 
            const selectedCollection = shopInfo.collections[newValue - 2];
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${selectedCollection.id}&sort=${sortBy ? sortBy : 'popular'}`)
        }
    };

    // Layout Functions
    const renderAllCollections = (idx) => {
        return (
            <div>
                {
                    _.size(shopInfo?.collections) > 0 ?
                        _.map(shopInfo?.collections, collectionItem => {
                            if (idx === collectionItem.id) {
                                return (
                                    <Grid container key={collectionItem.id} className='p-t-15'>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                                                {
                                                    _.size(collectionItem.product_ids_array) > 0 ?
                                                        _.map(collectionItem.product_ids_array, productItem => {
                                                            let showRetail = false;
                                                            let discountPercent = 0;
                                                            if (parseFloat(productItem.retail_price) > 0 && parseFloat(productItem.retail_price) > parseFloat(productItem.sell_price)) {
                                                                showRetail = true;
                                                                discountPercent = ((parseFloat(productItem.retail_price) - parseFloat(productItem.sell_price)) / parseFloat(productItem.retail_price)) * 100;
                                                                discountPercent = Math.round(discountPercent);
                                                            }
                                                            return (
                                                                <Grid item xs={6} key={productItem.id} className={styles.cardContentRootStyle}>
                                                                    <ProductItemCard product={productItem} currencyCode={currency_code} />
                                                                </Grid>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })
                        : null
                }
            </div>
        )
    }

    const renderAllProducts = () => {
        return (
            <>
                {
                    state?.tabIndex === 0 &&
                    <div className='w-full flex-sb-m'>
                        <p className='fs-title cl-theme txt-upper'>{t('shop.allProduct')}</p>
                    </div>
                }

                <Box className='flex-c-m p-t-15'>
                    <Button
                        size="medium"
                        style={{ fontSize: 12, flex: 1 }}
                        disableElevation
                        onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=popular`)}
                    >
                        <Typography color={!sortBy || sortBy === 'popular' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.popular')}</Typography>
                    </Button>
                    <Button
                        size="medium"
                        style={{ fontSize: 12, flex: 1 }}
                        disableElevation
                        onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=latest`)}
                    >
                        <Typography color={sortBy === 'latest' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.latest')}</Typography>
                    </Button>
                    <Button
                        size="medium"
                        style={{ fontSize: 12, flex: 1 }}
                        disableElevation
                        onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=topSales`)}
                    >
                        <Typography color={sortBy === 'topSales' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.topSales')}</Typography>
                    </Button>
                    <Typography>|</Typography>
                    <Button
                        size="medium"
                        style={{ fontSize: 12, flex: 1 }}
                        disableElevation
                        onClick={() => {
                            const priceSort = sortBy === 'priceDesc' ? 'priceAsc' : 'priceDesc';
                            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=${priceSort}`)
                        }}
                        endIcon={
                            sortBy === 'priceDesc' ?
                                <KeyboardArrowDownIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                : <KeyboardArrowUpIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                        }
                    >
                        <Typography color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.price')}</Typography>
                    </Button>
                </Box>

                <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                    {
                        _.size(shopCategoryProduct) > 0 ?
                            _.map(shopCategoryProduct, categoryProduct => {
                                let showRetail = false;
                                let discountPercent = 0;
                                if (parseFloat(categoryProduct.retail_price) > 0 && parseFloat(categoryProduct.retail_price) > parseFloat(categoryProduct.sell_price)) {
                                    showRetail = true;
                                    discountPercent = ((parseFloat(categoryProduct.retail_price) - parseFloat(categoryProduct.sell_price)) / parseFloat(categoryProduct.retail_price)) * 100;
                                    discountPercent = Math.round(discountPercent);
                                }
                                return (
                                    <Grid item xs={6} key={categoryProduct.id} className={styles.cardContentRootStyle}>
                                        <ProductItemCard product={categoryProduct} currencyCode={currency_code} />
                                    </Grid>
                                )
                            })
                            :
                            <Box minHeight={200} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                {
                                    shopCategoryProduct === null
                                        ? <CircularProgress disableShrink />
                                        : <Typography variant="caption">{t('general.noProduct')}</Typography>
                                }
                            </Box>
                    }
                </Grid>

                {
                    _.size(shopCategoryProduct) > 0 &&
                    <Grid container spacing={1} className='flex-c p-t-15'>
                        <Pagination count={totalPage} shape="rounded" page={page}
                            renderItem={(item) => (
                                <PaginationItem
                                    component={RouterLink}
                                    to={`/shop/${shopSlug}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                }
            </>
        )
    }

    const renderAllShopInfo = () => {
        return (
            <Grid container className='bor10 p-all-10' style={{ background: '#fff', boxShadow: theme.shadows[2] }}>
                <Grid item xs={12}>
                    <Box className='flex-c-m'>
                        {
                            _.size(shopInfo?.merchant_company_icon) > 0 &&
                                <Box className='flex-c-m p-all-20 bor10'>
                                    <WebpImg 
                                        containerStyle={{ borderRadius: 10, height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
                                        src={shopInfo?.merchant_company_icon?.file_name || "/images/empty/shop-nologo2.png"} 
                                        style={{ borderRadius: 10, width: 'fit-content', padding: '0', display: 'block', objectFit: 'contain', height: 120 }} 
                                        alt={`${shopInfo?.[`shop_name_${i18n.language}`]}` || "shoplogo-default"} 
                                    />
                                </Box>
                        }
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography color="primary" style={{ fontSize: 20, textAlign: 'center' }}><b>{shopInfo?.[`shop_name_${i18n.language}`]}</b></Typography>
                    <Typography variant="body1" style={{ fontSize: 12, textAlign: 'justify', textAlignLast: 'center' }}>{shopInfo?.[`about_${i18n.language}`]}</Typography>

                    <Box className='flex-sa-m m-t-3' width="100%">
                        <Box className='flex-col-c-m'>
                            <Box className='flex-m' minHeight={38}>
                                <NumberFormat color="primary" className={styles.iconDescStyle} style={{ color: theme.palette.primary.main, fontSize: 21, lineHeight: '39px' }} value={shopInfo?.total_product > 0 ? shopInfo?.total_product : 0} displayType={'text'} thousandSeparator={true} />
                            </Box>
                            <Box className='flex-m' minHeight={30}>
                                <Typography style={{ fontSize: 14, color: '#8c8c8c' }} className={styles.iconTitleStyle}>{t('shop.product')}</Typography>
                            </Box>
                        </Box>

                        <Box className='flex-col-c-m'>
                            <Box className='flex-m' minHeight={38}>
                                <Typography color="primary" style={{ fontSize: 23 }} className={styles.iconDescStyle}>{_.size(shopInfo) > 0 ? _.size(shopInfo?.shop_average_review?.positive_score) > 0 ? shopInfo?.shop_average_review?.positive_score : 0 : 0}<span style={{ fontSize: 18, paddingLeft: 5 }}>%</span></Typography>
                            </Box>
                            <Box className='flex-m' minHeight={30}>
                                <Typography style={{ fontSize: 14, color: '#8c8c8c', textAlign: 'center' }} className={styles.iconTitleStyle}>{t('shop.shopRating')}</Typography>
                            </Box>
                        </Box>

                        <Box className='flex-col-c-m'>
                            <Box className='flex-b' minHeight={38}>
                                <Typography color="primary" style={{ fontSize: 24, lineHeight: '1.5' }} className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? shopInfo.joined_month : shopInfo.joined_day || 0}</Typography>
                                <Typography style={{ fontSize: 14, paddingBottom: 5, paddingLeft: 5 }} color="primary" className={styles.iconDescStyle}>{shopInfo.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}</Typography>
                            </Box>
                            <Box className='flex-m' minHeight={30}>
                                <Typography style={{ fontSize: 14, color: '#8c8c8c' }} className={styles.iconTitleStyle}>{t('shop.joined')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const renderAllReviews = () => {
        return (
            <Grid container className='m-t-15 bor10 p-all-10' style={{ background: '#fff', boxShadow: theme.shadows[2] }}>
                <Grid item xs={12}>
                    <Typography variant='h6' style={{ fontWeight: 'bold', color: '#8c8c8c', textAlign: 'center' }}>{t('shop.shopRating')}</Typography>
                    
                    <Box className='flex-col-sa p-b-20' style={{ height: '100%' }}>
                        {/* <Box>
                            {
                                // _.size(positive_score) > 0 ?
                                // <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>{shopInfo.shop_average_review.positive_score}<span style={{fontSize:29, paddingLeft:5}}>%</span></Typography>
                                // :
                                // <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>- <span style={{fontSize:29, paddingLeft:5}}>%</span></Typography>
                            }
                            <Typography color="primary" variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {_.size(shopInfo) > 0 ? _.size(shopInfo?.shop_average_review?.positive_score) > 0 ? shopInfo?.shop_average_review?.positive_score : 0 : 0}<span style={{ fontSize: 29, paddingLeft: 5 }}>%</span>
                            </Typography>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'center', color: '#8c8c8c' }}>{t('shop.shopRating')}</Typography>
                        </Box> */}

                        <Box className='flex-sa-m'>
                            <Box className='flex-col-c-m'>
                                <SentimentVeryDissatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                <Typography style={{ fontSize: 12 }}>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[1]})` || 0}</Typography>
                            </Box>
                            <Box className='flex-col-c-m'>
                                <SentimentSatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                <Typography style={{ fontSize: 12 }}>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[2]})` || 0}</Typography>
                            </Box>
                            <Box className='flex-col-c-m'>
                                <SentimentVerySatisfiedIcon style={{ color: theme.palette.gray.main }} />
                                <Typography style={{ fontSize: 12 }}>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[3]})`|| 0}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {
                        _.size(review) > 0 
                            ? <Box>
                                <Box className='flex-col p-t-2'>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>{t('shop.sellerRatingsReviews')}</Typography>
                                </Box>
                                <Box className='flex'>
                                    {
                                        _.map([3, 2, 1], satisfactionSort => {
                                            return (
                                                <Button
                                                    key={`${satisfactionSort}score`}
                                                    color={reviewSort === `${satisfactionSort}score` ? 'primary' : 'default'}
                                                    className={styles.buttonStyle}
                                                    style={{ borderColor: reviewSort === satisfactionSort ? theme.palette.primary.main : '#dadada' }}
                                                    onClick={() => {
                                                        setReviewPage(1)
                                                        setReviewSort(satisfactionSort)
                                                    }}
                                                >
                                                    <Box className='flex-col-c'>
                                                        {
                                                            satisfactionSort === 3 ?
                                                                <SentimentVerySatisfiedIcon style={{ color: reviewSort === 3 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                : satisfactionSort === 2 ?
                                                                    <SentimentSatisfiedIcon style={{ color: reviewSort === 2 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                    : satisfactionSort === 1 ?
                                                                        <SentimentVeryDissatisfiedIcon style={{ color: reviewSort === 1 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                        : null
                                                        }
                                                        {/* <Typography className={ styles.sortingTitle }>{`(${productReviewSummary.star_count[satisfactionSort]})`}</Typography> */}
                                                    </Box>
                                                </Button>
                                            )
                                        })
                                    }
                                </Box>

                                <Box padding={2}>
                                    {
                                        _.map(review, reviewItem => {
                                            return (
                                                <Box key={reviewItem.id}>
                                                    <Box className='flex-m'>
                                                        {
                                                            reviewItem.rating === 3 ?
                                                                <SentimentVerySatisfiedIcon color="primary" fontSize="large" />
                                                                : reviewItem.rating === 2 ?
                                                                    <SentimentSatisfiedIcon color="primary" fontSize="large" />
                                                                    : reviewItem.rating === 1 ?
                                                                        <SentimentVeryDissatisfiedIcon color="primary" fontSize="large" />
                                                                        : null
                                                        }
                                                        <Typography style={{ margin: 0, paddingLeft: 10 }}>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</Typography>
                                                    </Box>

                                                    <Box marginTop={2}>
                                                        <Typography style={{ fontSize: 13, paddingBottom: 10 }}>{reviewItem.comment}</Typography>
                                                    </Box>

                                                    {
                                                        _.size(reviewItem.response) > 0 
                                                            ? <Box className='p-all-2 bor10 m-b-1' style={{ backgroundColor: '#f1f1f1' }}>
                                                                <Box className='flex-sb-m'>
                                                                    <Typography className={styles.merchantReplyTitle}>{t('item.merchantReply')}</Typography>
                                                                    <Typography className={styles.merchantReplyTitle}>{reviewItem.updated_date_display}</Typography>
                                                                </Box>
                                                                <Typography style={{ fontSize: 13 }}>{reviewItem.response}</Typography>
                                                            </Box>
                                                            : null
                                                    }
                                                    <Typography style={{ margin: 0, fontSize: 13, color: '#989898' }}>{reviewItem.created_date_display}</Typography>

                                                    <Divider style={{ margin: '20px 0' }} />
                                                </Box>
                                            )
                                        })
                                    }
                                    <Box className='flex-c'>
                                        <Pagination count={reviewTotalPage} shape="rounded" page={page}
                                            onChange={(event, value) => setReviewPage(value)}
                                            renderItem={(item) => (
                                                <PaginationItem {...item} />
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            : <div>
                                <div className='empty-img'>
                                    <img src="/images/empty/data.png" className='w-full' alt="empty" />
                                </div>
                                <p className='txt-center fs-content'>{t('shop.noRatingReview')}</p>
                            </div>
                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <TitleBar currencyButton displayCart back />
            
            <div style={{ backgroundImage:'url(/images/general/bkg/gradient.png)', backgroundSize:'cover', backgroundRepeat:'repeat-y', backgroundPosition:'center center'}}>
                <div className='flex-b' style={{ height: 150, backgroundImage:`linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1.0)), url(${shopInfo?.merchant_company_banners?.[0]?.file_name})`, backgroundSize: 'cover', backgroundRepeat:'repeat-y', backgroundPosition:'center' }}>
                    <ShopInfoCard shopInfo={shopInfo} />
                </div>

                <Tabs
                    value={state.tabIndex}
                    onChange={handleChange}
                    variant={_.size(shopInfo.collections) > 0 ? "scrollable" : "fullWidth"}
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ backgroundColor: '#fff', paddingTop: 5, position: 'sticky', top: 0, zIndex: 3 }}
                >
                    <Tab label={t('shop.home')} disableRipple />
                    <Tab label={t('shop.allProduct')} disableRipple />
                    {
                        _.size(shopInfo.collections) > 0 &&
                        _.map(shopInfo.collections, (collectionItem, collectionIndex) => {
                            return (
                                <Tab key={collectionIndex} value={collectionIndex + 2} label={collectionItem[`title_${i18n.language}`]} disableRipple />
                            )
                        })
                    }
                    <Tab label={t('shop.info')} value={999} disableRipple />
                </Tabs>

                <div className='p-t-10' style={{ background: 'transparent', width: '100%' }}>
                    <TabPanel value={state.tabIndex} index={0} dir={theme.direction} className='p-lr-15'>
                        <div className='m-t-15'>
                            {
                                _.size(shopInfo?.merchant_company_banners) > 0
                                    ? <AutoplaySlider
                                        play={true}
                                        cancelOnInteraction={false}
                                        interval={3000}
                                        bullets={false}
                                        organicArrows={false}
                                        className="home"
                                        style={{ height: 150 }}
                                    >
                                        {
                                            _.map(shopInfo?.merchant_company_banners, bannerItem => {
                                                return (
                                                    <div key={bannerItem.id} className="awssld__content">
                                                        <WebpImg
                                                            src={bannerItem.file_name}
                                                            alt={bannerItem.file_name}
                                                            style={{ height: 150, objectFit: 'cover', display: 'block', width: '100%' }}
                                                            containerStyle={{ height: '100%', width: '100%' }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </AutoplaySlider>
                                    : <Box className="shop bor15" style={{ height: 150 }}>
                                        <WebpImg src="/images/empty/shop-noimage4.png" alt="noimage" style={{ height: 150, objectFit: 'cover', display: 'block', width: '100%' }} />
                                    </Box>
                            }
                        </div>
                        
                        <div className='p-b-15'>
                            {
                                _.size(shopInfo?.collections) > 0 &&
                                _.map(shopInfo?.collections, collectionItem => {
                                    if (_.size(collectionItem.product_ids_array) > 0) {
                                        return (
                                            <div className='p-t-15' key={collectionItem.id}>
                                                <div className='w-full flex-sb-m'>
                                                    <p className='fs-title cl-theme txt-upper'>{collectionItem[`title_${i18n.language}`]}</p>
                                                    <Link underline='none' to={`/collection/${collectionItem.id}`} component={RouterLink} className='translateX flex-m'>
                                                        <p className='fs-content cl-theme p-r-5'>{`${t('shop.seeAll')} >`}</p>
                                                    </Link>
                                                </div>

                                                <Slider
                                                    infinite={_.size(collectionItem.product_ids_array) > 1 ? true : false}
                                                    speed={500}
                                                    slidesToShow={1}
                                                    slidesToScroll={1}
                                                    autoplay={true}
                                                    pauseOnHover={true}
                                                    bullets={true}
                                                    arrows={false}
                                                    // responsive={[
                                                    //     { breakpoint: 1024, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, infinite: true, dots: true } },
                                                    //     { breakpoint: 992, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                    //     { breakpoint: 600, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                    // ]}
                                                    customPaging={(i) => (
                                                        <div className="custom-dot"></div> // You can use <CustomDot /> if you created the CustomDot component
                                                    )}
                                                    appendDots={(dots) => (
                                                        <div>{dots}</div>
                                                    )}
                                                >
                                                    {
                                                        _.map(_.slice(collectionItem.product_ids_array, 0, 6), (product) => {
                                                            return (
                                                                <Grid item xs={12} key={product.id} className={styles.cardContentRootStyle}>
                                                                    {/* {
                                                                        collectionItem?.id === 1
                                                                            ? <CollectionItemCard product={product} currencyCode={currency_code} />
                                                                            : <ProductItemCard product={product} currencyCode={currency_code} />
                                                                    } */}
                                                                    <CollectionItemCard product={product} currencyCode={currency_code} />
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        
                        {renderAllProducts()}
                    </TabPanel>

                    <TabPanel value={state.tabIndex} index={1} dir={theme.direction} className='p-lr-15'>
                        {renderAllProducts()}
                    </TabPanel>

                    {
                        _.size(shopInfo?.collections) > 0 &&
                        _.map(shopInfo?.collections, (collectionItem, collectionIndex) => {
                            return (
                                <TabPanel key={collectionIndex} value={state.tabIndex} index={collectionIndex + 2} dir={theme.direction} className='p-lr-15'>
                                    {renderAllCollections(collectionItem.id)}
                                </TabPanel>
                            )
                        })
                    }

                    <TabPanel value={state.tabIndex} index={999} dir={theme.direction} className='p-lr-15'>
                        {renderAllShopInfo()}
                        {renderAllReviews()}
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'light',
    },
    paperRoot: {
        flexGrow: 1,
        borderWidth: 1,
        backgroundColor: 'transparent'
    },
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
    collectionList: {
        padding: '0px 10px 0px 10px',
    },
    collectionListFirst: {
        padding: '0px 10px 0px 0px',
    },
    collectionListLast: {
        padding: '0px 0px 0px 10px',
    },
    iconTitleStyle: {
        fontWeight: 'bold',
        fontSize: 12
    },
    iconDescStyle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    shopIcon: {
        objectFit: 'fill',
        height: 56,
        maxWidth: 102
    },
    buttonStyle: {
        width: '10%',
        backgroundColor: '#efefef',
        margin: '0px 10px',
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },
}));