import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Link, Box} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TitleBar from '@layouts/TitleBar';


export default function ShippingPolicy() {
    const { t } = useTranslation();

    return (
        <Box>
            <TitleBar backgroundColor="bg-theme" back displayUsername spendButton />
            <div className='bg-theme p-b-30 header-radius'>
                <p className='txt-center fs-header clwhite p-t-30'>{t('support.shippingPolicy')}</p>
            </div>
            <div className='p-t-30'>
                <div className='lh-17'>
                    <Container>
                        <p>ORDER PROCESSING:</p>
                        <p>Please allow 2 days to process your order.</p>
                        <br/>
                        <p style={{ color: '#333' }}><b>LOCAL DELIVERY</b></p>
                        <p>During select the product and click checkout, enter your address. Process your payment. Click the "Place Order" button. Browse and verify through the item, address, and payment type, then Click “Pay Now”</p>

                        <br /><p>Orders placed before [4PM] during store hours [8am – 8pm] will be delivered the same day. Orders placed outside of these hours will be delivered the next day we are operated.</p>
                        
                        <br /><p>When your order is ready for delivery, you will receive an email with real-time tracking information.</p>
                    </Container>
                </div>
            </div>
        </Box>
    );
}
