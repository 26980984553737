import React, { useState } from 'react';
// import { IoArrowUpCircleSharp } from "react-icons/io5";
import { BiArrowToTop } from 'react-icons/bi';

export default function BackToTop() {
    const [visible, setVisible] = useState(false);
    const [resize, setResize] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100) {
            setVisible(true);
        }
        else if (scrolled <= 100) {
            setVisible(false);
        }
    };

    const resizeWindow = () => {
        const size = document.documentElement.clientWidth;
        if (size < 420) {
            setResize(true);
        } else if (size >= 420) {
            setResize(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    window.addEventListener('resize', resizeWindow);

    return (
        <div className='flex-r'>
            <div style={{
                color: '#666666',
                display: visible ? 'inline' : 'none',
                zIndex: 99,
                bottom: 70,
                // right: resize ? 15 : 240,
                position: 'fixed',
                // width: resize ? '100%' : 420,
                textAlign: 'end',
                padding: '0px 20px',
            }}
            >
                <BiArrowToTop onClick={scrollToTop} size="40" style={{ background: 'rgba(0, 0, 0, 0.49)', color: '#fff', borderRadius: 49, padding: 3, cursor: "pointer" }} />
            </div>
        </div>
    );
}