import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';

import { getUrl, putUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { CircularProgress, FormControl, FormControlLabel, Grid, Link, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { CheckCircle, StorefrontOutlined } from '@material-ui/icons';
import { Edit3 } from 'react-feather';

// COMPONENTS & LAYOUTS
import WebpImg from '@layouts/WebpImg';

export default function DirectCheckoutSellerCard(props) {
    const { cart, mallShipping, setMallShipping, fetchingMallShipping, walletPoint, setWalletPoint, currency_code, currency, currencyRate, currencyData, remark, setRemark, selfPickUpInfo, setSelfPickupInfo, cartErrors, mallFee, setMallFee, setMallTotalShipping, pickup, setPickUp, setDisabledSubmit, shippingWallet, wallets, isMalaysiaShipping, malaysiaShippingArea, state, addresses, setFetchingMallShipping, setIsMalaysiaShipping, setMalaysiaShippingArea, mallShippingRefresh, setShippingWallet, setCartError , directPayment } = props;
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    const [shippingFeeOpen, setShippingFeeOpen] = useState([]);
    const [merchantCount, setMerchantCount] = useState(0);

    // SET MERCHANT COUNT  
    useEffect(() => {
        let _mcount = 0;
        _.map(cart, (merchantItem, merchantId) => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                let merchantGotSelectedProduct = _.find(cartDetails, function(d){return d.selected;});
                if(merchantGotSelectedProduct){
                    _mcount += 1;
                }
            })
        })
        setMerchantCount(_mcount);
        return;
    }, [cart]);

    // SET SHIPPING WALLET
    useEffect(() => {
        getUrl('shipping_fee/wallet').then(response => {
            if (response.status) {
                let shippingFeePrefix = '';
                if (currencyData) {
                    shippingFeePrefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                }
                if (_.size(response.data) > 0) {
                    let name = _.split(response.data.name, '|');
                    shippingFeePrefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                    setShippingWallet({ id: response.data.id, prefix: shippingFeePrefix, decimal: response.data.decimal });
                } else {
                    setShippingWallet({ id: 0, prefix: shippingFeePrefix, decimal: 2 });
                }
            } else {
                addAlert(JSON.stringify(response.data));
                setDisabledSubmit(true);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return;
    }, [i18n.language, addAlert, currencyData]);

    // SET MALL SHIPPING
    useEffect(() => {
        isMountedRef.current = true;
        if (state.addressId !== null) {
            setDisabledSubmit(true);
            setFetchingMallShipping(true);
            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            const updateData = {
                user_zip: selectedAddress ? selectedAddress.zip : '',
                user_state: selectedAddress ? selectedAddress.state : '',
                user_country: selectedAddress ? selectedAddress.country : '',
            }
            putUrl('mall/shipping_fee', updateData).then(response => {
                setFetchingMallShipping(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setMallShipping(response.data);
                        let checkDisableSubmit = response.error;
                        _.map(response.data, (merchantItem, merchantIndex) => {
                            let merchant_channel = _.find(selfPickUpInfo, { 'id': merchantIndex });
                            if (_.size(merchant_channel) > 0) {
                                if (merchant_channel.self_pickup) {
                                    checkDisableSubmit = false;
                                }
                            }
                        })
                        setMallFee(0);
                        setDisabledSubmit(checkDisableSubmit);
                        setMallTotalShipping(response.total_shipping ? response.total_shipping : 0);
                    } else {
                        addAlert(JSON.stringify(response.data));
                        setDisabledSubmit(true);
                    }
                }
            }).catch(error => {
                setFetchingMallShipping(false);
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [addAlert, addresses, state.addressId, mallShippingRefresh]);

    // GET MALAYSIA SHIPPING AREA
    useEffect(() => {
        if (_.size(addresses) > 0 && state.addressId > 0) {
            const selectedAddress = _.find(addresses, { id: state.addressId });
            if (_.size(selectedAddress) && selectedAddress.country === 'MY') {
                setIsMalaysiaShipping(true);
                getUrl(`countries/${selectedAddress.country}`).then(response => {
                    if (response.status) {
                        _.map(response.data, (regionState, region) => {
                            const existState = _.find(regionState, { id: parseInt(selectedAddress.state) });
                            if (_.size(existState)) {
                                setMalaysiaShippingArea(region);
                            }
                        })
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                setIsMalaysiaShipping(false);
            }
        }
    }, [state.addressId, addresses, cart]);

    const handleRemarkChange = (event, merchantId) => {
        setRemark({ ...remark, [merchantId]: event.target.value });
    }

    const handleShippingFeeClick = (merchantId) => {
        setShippingFeeOpen({ ...shippingFeeOpen, [merchantId]: !shippingFeeOpen[merchantId] });
    }
    const handleMallShippingFee = (event, merchantId) => {
        let newValue = parseInt(event.target.value);
        setMallShipping({
            ...mallShipping, [merchantId]: {
                ...mallShipping[merchantId], selected_rate: {
                    key: newValue,
                    shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
                    courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
                    service_id: mallShipping[merchantId].rate_list[newValue].service_id,
                    courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
                }
            }
        });
        let tempMall = mallShipping;
        tempMall[merchantId].selected_rate = {
            key: newValue,
            shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
            courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
            service_id: mallShipping[merchantId].rate_list[newValue].service_id,
            courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
        };
        let totalShippingFee = 0;
        _.map(tempMall, (value) => {
            if (!value.error && value.selected_rate) {
                totalShippingFee += parseFloat(value.selected_rate.shipping_fee);
                setMallFee(prevState => ({
                    ...prevState,
                    [merchantId]: {
                        fee: value.selected_rate.shipping_fee,
                    }
                }))
            }
        })
        setMallTotalShipping(totalShippingFee);
    }

    const getProductPrice = (cartItem) => {
        let productPrice = parseFloat(cartItem.product.sell_price);
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                    }
                })
            })
        }
        return productPrice;
    }
    
    const priceDisplay = (cartItem) => {
        let productPrice = getProductPrice(cartItem);
        return (
            <div>
                <div className='flex-sb-m w-full' style={{ color: theme.palette.gray.text }}>
                    <p className='fs-conent'>
                        <NumberFormat value={parseFloat(productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                    </p>
                    <p className='fs-conent'>x {cartItem.quantity}</p>
                </div>
                {/* <p className='fs-14 cl-theme'>
                    <b><NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></b>
                </p> */}
            </div>
        )
    }

    const multiPriceDisplay = (cartItem) => {
        let productPrice = cartItem.product.sell_price;
        // console.log(cartItem)
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                    }
                })
            })
        }
        
        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
            return (
                <div>
                    {_.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                        if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                            let pricingText = [];
                            _.map(pricingItem.wallet_settings, (setting, key) => {
                                let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                pricingText[key] = { 'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(2), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(2) };
                            })
                            
                            return (
                                <div key={pricingIndex}>
                                    {_.map(pricingText, (data, key) => {
                                        return (
                                            <Typography variant="overline" key={key} style={{ lineHeight: 1.3, marginRight: 5 }}>
                                                <NumberFormat value={data.min_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={data.prefix} />
                                                {
                                                    data.min_amount !== data.max_amount
                                                        ?
                                                        <NumberFormat value={data.max_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={' - '} />
                                                        : null
                                                }
                                            </Typography>
                                        )
                                    })}
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
        return null;
    }

    const handleChannelChange = (channel, merchantId, mallShippingFee) => {
        let merchant = _.find(selfPickUpInfo, { 'id': merchantId });
        let shipping_fee = _.find(mallFee, { 'id': merchantId });
        let total_deduct = pickup;
        // console.log("merchant",merchant);

        if (_.size(merchant) > 0) {
            setSelfPickupInfo(prevState => ({
                ...prevState,
                [merchant.id]: {
                    // id:merchant.id,
                    ...prevState[merchant.id],
                    channel: channel,
                    self_pickup: channel == 'pickup' ? true : false,
                    outlet_id: 0,
                }
            }))

            //for mall shipping
            if (channel == 'pickup') {
                if (shipping_fee && _.size(shipping_fee) > 0) {
                    total_deduct += shipping_fee.fee;
                } else if (mallShippingFee && !mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee) {
                    total_deduct += mallShipping[merchantId].selected_rate.shipping_fee;
                }
                setPickUp(total_deduct);

                if (mallShipping[merchantId] && mallShipping[merchantId].error == true) {
                    setDisabledSubmit(false);
                }

            } else {
                if (shipping_fee && _.size(shipping_fee) > 0) {
                    total_deduct -= shipping_fee.fee;
                } else if (mallShippingFee && !mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee) {
                    total_deduct -= mallShipping[merchantId].selected_rate.shipping_fee;
                }
                setPickUp(total_deduct);

                if (mallShipping[merchantId] && mallShipping[merchantId].error == true) {
                    setDisabledSubmit(true);
                }
            }
        }

    }

    const handleOutletChange = (outlet_id, merchantId) => {
        let merchant = _.find(selfPickUpInfo, { 'id': merchantId });
        if (_.size(merchant) > 0 && merchant.channel == 'pickup') {
            setSelfPickupInfo(prevState => ({
                ...prevState,
                [merchant.id]: {
                    // id:merchant.id,
                    // channel:'pickup',
                    // self_pickup:true,
                    ...prevState[merchant.id],
                    outlet_id: outlet_id,
                }
            }))
        }
        // console.log("selfpickupinfo",selfPickUpInfo)
    }

    const handleWalletPointChange = (cartItem, wallet_id, value) => {
        let productPrice = cartItem.product.sell_price;
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                    }
                })
            })
        }
        let totalPrice = cartItem.quantity * productPrice;
        let sumTotal = 0;
        let isSetCash = false;
        _.map(walletPoint[cartItem.id], (amount, wid) => {
            if (wid != 'cash') {
                if (wid == wallet_id) {
                    sumTotal += parseFloat(value) ? parseFloat(value) : 0;
                } else {
                    sumTotal += parseFloat(amount);
                }
            } else {
                isSetCash = true;
            }
        })
        // console.log('sumTotal', sumTotal);
        if (isSetCash && wallet_id != 'cash') {
            let cash = totalPrice - sumTotal;
            if (cash > 0) {
                setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value, ['cash']: cash } });
            } else {
                setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value, ['cash']: 0 } });
            }
        } else {
            setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value } });
        }
    }

    return (
        <div>
            {
                _.map(cart, (merchantItem, merchantId) => {
                    let showMerchant = false;
                    let selfShippingFee = false;
                    let mallShippingFee = false;
                    let shippingFeePrefix = shippingWallet.prefix;
                    let outlet_list = [];
                    let merchant_channel_list = _.find(selfPickUpInfo, { 'id': merchantId });

                    if (_.size(merchantItem.outlets) > 0) {
                        outlet_list = merchantItem.outlets;
                    }

                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        _.map(cartDetails, cartItem => {
                            if (cartItem.selected) {
                                showMerchant = true;
                                if (shippingChannel === 'self') {
                                    selfShippingFee = true;
                                }
                            }
                        })
                    })
                    _.map(mallShipping, (value, merId) => {
                        if (merchantId === merId) {
                            mallShippingFee = true;
                        }
                    })

                    if (showMerchant) {
                        return (
                            <div key={merchantId} className={`w-full ${merchantCount > 1 && ' glass2 shadow-glass2 bor15 p-all-10 m-b-30'}`}>
                                <div className='w-full flex-sb-m p-b-15 '>
                                    <div className='flex-m cl-label '>
                                        <StorefrontOutlined className='fs-icon' />
                                        <p className='fs-title p-l-10'><b>{merchantItem.name}</b></p>
                                    </div>
                                    <Link underline='none' to="/cart" component={RouterLink}>
                                        <div className='btn-square25-theme'>
                                            <Edit3 className='fs-icon' />
                                        </div>
                                    </Link>
                                </div>
                                

                                <div className={` ${(merchant_channel_list) && (merchant_channel_list.channel == 'pickup') && 'glass2 shadow-glass2 bor15 p-all-15 m-b-20'}`}>
                                    {
                                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                            let showBox = _.find(cartDetails, function(d){return d.selected;});
                                            if(showBox){
                                                return (
                                                <div key={shippingChannel} className={`productlist ${(merchant_channel_list) && (merchant_channel_list.channel == 'delivery') && 'glass2 shadow-glass2 bor15 p-all-15 m-b-20'}`}>
                                                    {
                                                        _.map(cartDetails, (cartItem, cartIndex) => {
                                                            if (cartItem.selected) {
                                                                const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                let variant_translate = [];
                                                                let even = (_.size(walletPoint[cartItem.id])) % 2 === 0;

                                                                _.map(cartItem.product.options, (option) => {
                                                                    _.map(option.pricing, (pricingOption) => {
                                                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                            if (option.color) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                            }
                                                                            if (option.size) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                            }
                                                                            if (option.attribute_value) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                            }
                                                                        }
                                                                    })
                                                                });
                                                                return (
                                                                    <div key={cartIndex}>
                                                                        <div className='p-tb-25 divider'>
                                                                            <div className='p-tb-3 divider-white' />
                                                                        </div>
                                                                        <div>
                                                                            <div className='flex-sb-m w-full'>
                                                                                <Link underline='none' color="inherit" to={`/product/${cartItem.product.id}`} style={{ display: 'flex', alignItems: 'center', }} component={RouterLink}>
                                                                                    <WebpImg containerClass="product-img-size" className="product-img-size" alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                </Link>
                                                                                <div className='w-full p-lr-15'>
                                                                                    <div>
                                                                                        <Link underline='none' color="inherit" to={`/product/${cartItem.product.id}`} component={RouterLink}><p className='lh-12 fs-title'>{title_translate}</p></Link>
                                                                                    </div>
                                                                                    {
                                                                                        _.size(variant_translate) > 0 ?
                                                                                            <div className='box-variant'>
                                                                                                {/* <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography> */}
                                                                                                {
                                                                                                    _.map(variant_translate, variantText => {
                                                                                                        return (
                                                                                                            <p key={variantText} className='fs-remark p-lr-5'>{variantText > 0 ? ", " : ""}{variantText}</p>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        merchant_channel_list && merchant_channel_list.channel == 'pickup' ?
                                                                                            null :
                                                                                            cartItem.product.shipping_channel === 'mall' && mallShippingFee && mallShipping[merchantId].error && mallShipping[merchantId].error_msg
                                                                                                ?
                                                                                                <p className='cl-error fs-remark'>{mallShipping[merchantId].error_msg}</p>
                                                                                                :
                                                                                                null
                                                                                    }
                                                                                    <div>
                                                                                        {/* <Typography style={{ color: theme.palette.gray.text }}>x{cartItem.quantity}</Typography> */}
                                                                                        <div className='fs-digit-small'>{priceDisplay(cartItem)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                _.size(walletPoint[cartItem.id]) > 0
                                                                                    ?
                                                                                    <div className='w-full p-t-15'>
                                                                                        <p className='fs-remark'>
                                                                                            {t('checkout.walletPayment')}
                                                                                        </p>
                                                                                        <div className=' p-b-10'>
                                                                                            {multiPriceDisplay(cartItem)}
                                                                                        </div>
                                                                                        {/* <div className='w-full flex-sb-m flex-w' style={{ gap: 10 }}>
                                                                                            {
                                                                                                _.map(walletPoint[cartItem.id], (amount, wid) => {
                                                                                                    let prefix = ` ${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `;
                                                                                                    if (wid !== 'cash') {
                                                                                                        let wallet = _.find(wallets, { 'id': parseInt(wid) });
                                                                                                        if (wallet) {
                                                                                                            prefix = wallet.wallet_name ? wallet.wallet_name : wallet.code;
                                                                                                        }
                                                                                                    }
                                                                                                    return (
                                                                                                        <div className={`${even ? 'even-last' : 'odd-last'}`} key={wid}>
                                                                                                            <TextField
                                                                                                                fullWidth={true}
                                                                                                                variant="outlined"
                                                                                                                onChange={(event) => handleWalletPointChange(cartItem, wid, event.target.value)}
                                                                                                                size="small"
                                                                                                                type="number"
                                                                                                                value={amount || ''}
                                                                                                                InputProps={{
                                                                                                                    endAdornment: <span>{prefix}</span>
                                                                                                                }}
                                                                                                                placeholder='0'
                                                                                                            />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div> */}

                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            <div className='flex-sb-m w-full fs-content p-t-15'>
                                                                                <p><b>{t('payment.subtotal')}</b></p>
                                                                                <p className='txt-right'><b><NumberFormat value={parseFloat(getProductPrice(cartItem) * currencyRate[`${currency}`] * cartItem.quantity).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></b></p>
                                                                            </div>

                                                                            {_.size(cartErrors[`cart.${cartItem.id}`]) ?
                                                                                <div className='p-t-5'>
                                                                                    {
                                                                                        _.map(cartErrors[`cart.${cartItem.id}`], (errMsg, key) => {
                                                                                            return(
                                                                                                <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                   
                                                    {
                                                        merchant_channel_list && merchant_channel_list.self_pickup == 1 ?
                                                            null :
                                                            shippingChannel === 'self' && selfShippingFee
                                                                ?
                                                                <div className='p-b-10'>
                                                                    <Grid container spacing={1} justify="center" >
                                                                        <Grid item xs={6}>
                                                                            <p className='fs-content'>{t('checkout.shippingFee')}: </p>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <p className='fs-content txt-right'>
                                                                                {
                                                                                    (isMalaysiaShipping && malaysiaShippingArea === 'east' ? parseFloat(merchantItem.shipping_fee2) : parseFloat(merchantItem.shipping_fee)) > 0
                                                                                    ?
                                                                                    <NumberFormat value={isMalaysiaShipping && malaysiaShippingArea === 'east' ? parseFloat(merchantItem.shipping_fee2).toFixed(2) : parseFloat(merchantItem.shipping_fee).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                    :
                                                                                    t('order.freeShipping')
                                                                                }
                                                                            </p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                :
                                                                null
                                                    }
                                                    {
                                                        merchant_channel_list && merchant_channel_list.self_pickup == 1 ?
                                                            null :
                                                            shippingChannel === 'mall' && mallShippingFee &&
                                                            <>
                                                                {
                                                                    fetchingMallShipping
                                                                        ?
                                                                        <div>
                                                                            <CircularProgress disableShrink size="1.5rem" />
                                                                        </div>
                                                                        :
                                                                        (!mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee)
                                                                            ?
                                                                            <div>
                                                                            {_.size(mallShipping[merchantId].rate_list) ?
                                                                                   <>
                                                                                   <p className='fs-content p-b-5'>{t('checkout.shippingOption')}</p>
                                                                                   <div className='bor10' style={{ width: '100%', background:'#efefef', padding:10,   }}>
                                                                                        <RadioGroup value={mallShipping[merchantId].selected_rate.key} onChange={(event) => handleMallShippingFee(event, merchantId)} >
                                                                                            {Object.values(mallShipping[merchantId].rate_list).map((row, key) => {
                                                                                                return (
                                                                                                    <div key={row.key} className='p-l-20' >
                                                                                                        <FormControlLabel
                                                                                                            value={row.key}
                                                                                                            control={<Radio size='small' />}
                                                                                                            label={
                                                                                                                <div>
                                                                                                                    <p className='fs-content' style={{ marginLeft: -32, paddingBottom: 7}} >{mallShipping[merchantId].selected_rate.courier_name}</p>
                                                                                                                    <div className='flex-m w-full'>
                                                                                                                        <div style={{ width: 80 }}>
                                                                                                                            <img src={row.courier_logo} alt={row.courier_name} />
                                                                                                                        </div>
                                                                                                                        <p className='txt-right p-l-10'>{`${(shippingWallet.prefix) + ' ' + row.price}`}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </RadioGroup>
                                                                                    </div>
                                                                                   </>
                                                                                    : null
                                                                                }

                                                                            <Grid container spacing={1} style={{paddingTop:20 }}>
                                                                                {/* <Button onClick={() => handleShippingFeeClick([merchantId])} fullWidth style={{ textTransform: 'none'}} > */}
                                                                                
                                                                                <Grid item xs={6} style={{ paddingLeft: '5%', textAlign: 'left' }}>
                                                                                    <p className='fs-content'>{t('checkout.shippingFee')}: </p>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{ paddingRight: 10, textAlign: 'right', alignSelf: 'center' }}>
                                                                                    <p className='fs-content'>
                                                                                        {
                                                                                            parseFloat(mallShipping[merchantId].selected_rate.shipping_fee) > 0
                                                                                            ?
                                                                                            <NumberFormat value={parseFloat(mallShipping[merchantId].selected_rate.shipping_fee).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                            :
                                                                                            t('order.freeShipping')
                                                                                        }
                                                                                    </p>
                                                                                </Grid>
                                                                               
                                                                            </Grid>
                                                                            </div>
                                                                            :
                                                                            // shipping
                                                                            <div className='p-b-10'>
                                                                                <Grid container spacing={1} justify="center" >
                                                                                    <Grid item xs={6}>
                                                                                        <p className='fs-content'>{t('checkout.shippingFee')} : </p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{ paddingRight: 10, color: mallShipping[merchantId].all_seller_cover ? "" : "red", textAlign: 'right' }}>
                                                                                        <Typography variant="body2">
                                                                                            {
                                                                                                mallShipping[merchantId].all_seller_cover
                                                                                                    ?
                                                                                                    t('order.freeShipping')
                                                                                                    :
                                                                                                    <NumberFormat value='0.00' decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                }
                                                            </>}
                                                </div>
                                            )
                                            }else{
                                                return null;
                                            }
                                            
                                        })
                                    }
                                </div>

                                <div className='p-b-10'>
                                    <TextField
                                        fullWidth={true}
                                        variant="filled"
                                        inputProps={{ name: 'name' }}
                                        placeholder={t('checkout.remarkMsg')}
                                        label={t('checkout.remark')}
                                        multiline
                                        onChange={(event) => { setCartError({ ...cartErrors, [`remark.${merchantId}`]: [] }); handleRemarkChange(event, merchantId) }}
                                        // value={ profileFormState.name }
                                        size="small"
                                    />
                                    {_.size(cartErrors[`remark.${merchantId}`]) ?
                                        <div className='p-t-5'>
                                            {
                                                _.map(cartErrors[`remark.${merchantId}`], (errMsg, key) => {
                                                    return (
                                                        <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <p className={`txt-right fs-remark p-t-5 ${_.size(remark?.[merchantId]) > 150 ? 'cl-error' : ''} `}>{t('general.maxCharacter')}: <b>{_.size(remark?.[merchantId]) || 0}</b> / 150</p>
                                    }
                                </div>
                            </div>
                        )
                    }
                    return null;
                })
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    imgStyle: {
        width: '100%'
    },
}));
