import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from "@helper/useNotificationLoading";
import { storeProfile } from "@actions";
import { putUrl } from "@helper/ApiAction";

//MUI
// import { makeStyles } from '@material-ui/core/styles';
import { Container, Link, Drawer, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

//ICON
// import { LuScrollText } from "react-icons/lu";
// import { IoChatbubblesOutline } from "react-icons/io5";
import { FiChevronRight, FiServer , FiAward  } from "react-icons/fi";
import { TbBinaryTree } from "react-icons/tb";
import { FiUserPlus } from "react-icons/fi";



//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


export default function Community() {
    const { t } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    // const theme = useTheme();
    // const styles = useStyles();
    const { additional_info } = useSelector((state) => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const [additionalInfoFormState, setAdditionalInfoFormState] = useState({
        ...additional_info,
    });

    return (

        <div>
            <TitleBar back btnRegister backUrl="/settings" spendButton />

            <Container>
                <div className='p-b-20'>
                    <p className='fs-title cl-theme'><b>{t('title.affiliatesCenter')}</b></p>
                </div>

                <div>
                    <Link underline='none' to="/wallet-home" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiAward className='fs-icon-small' />
                                <p className='cl-theme p-l-15 fs-content'>{t('title.bonusAndDiscount')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/collection/1" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiServer className='fs-icon-small' />
                                <p className='cl-theme p-l-15 fs-content'>{t('title.membership')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                </div>
            </Container>
        </div>
    );
}


// const useStyles = makeStyles(theme => ({



// }));


