import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _, { isNaN, isNil, } from 'lodash';
import { getUrl , postUrl } from '@helper/ApiAction';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import jsQR from "jsqr";
import { stringToLang } from '@helper/Tools';
import { storeWalletBalance } from '@actions';
import { useTheme } from '@material-ui/core/styles';
import { Container, Typography, Grid, TextField , InputAdornment , IconButton , Button, Select } from '@material-ui/core';
import useNotificationLoading from '@helper/useNotificationLoading';

import { FiEdit, FiHome } from "react-icons/fi";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import TitleBar from '@layouts/TitleBar';

const INITIAL_STATE = {
    amount: '',
    securityPassword: '',
    showSecurityPassword: false,
    openCamera: true,
    wallet_id: '',
};

export default function Spend() {
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const [result, setResult] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [selectedWallet , setSelectedWallet]= useState([]);
    const [extraTransferWallet , setExtraTransferWallet]= useState([]);
    const { accessToken, walletList, walletBalance } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const [permissionStatus, setPermissionStatus] = useState('unknown');

    const params = new URLSearchParams(window.location.search);
    const mid = params.get('m');

    const steps = [
        t('spend.merchant'),
        t('spend.makePayment'),
        t('spend.paymentSuccess'),
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getMerchantInfo = (value) => {
        if (!isNaN(value) && value > 0) {
            setLoading(true);
            getUrl(`/merchant_info`, { 'id': value }).then(response => {
                setLoading(false);
                let { status, message, error, data } = response;

                if (status) {
                    setSelectedMerchant(data?.merchant);
                    setSelectedWallet(data?.spend_wallets);
                    setExtraTransferWallet(data?.extra_wallet_transfer);
                    setActiveStep(1);
                } else {
                    addAlert(message || t('error.contactSupport'), 'error', '','');
                    setState({ ...state, openCamera: true });
                    startCamera();
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const handleScan = () => {
        const video = videoRef.current;

        if (!video) {
            // Video element not yet initialized, exit function
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context) {
            console.error('Canvas context not available');
            return;
        }

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code){
            let splitText = (code.data).split("?m=");
            if (_.size(splitText) > 0) {
                let decodedId = atob(splitText[1]);
                if (decodedId > 0) {
                    cancelAnimationFrame(handleScan);
                    getMerchantInfo(decodedId);
                    setState({ ...state, openCamera: false });
                    stopCamera();
                }
            }
        } else {
            // Repeat capture process
            requestAnimationFrame(handleScan);
            console.log('QR code not detected');
        }

    };

    // Access the camera and start the video stream
    const startCamera = () => {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
            .then(stream => {
                const video = videoRef.current;
                video.srcObject = stream;
                video.onloadedmetadata = () => {
                    video.play()
                        .then(() => {
                            console.log('Video started successfully.');
                            handleScan(); // Delay scanning for 1 second to ensure video has started
                        })
                        .catch(error => {
                            console.error('Error starting video playback:', error);
                        });
                };
            })
            .catch(error => {
                console.error('Error accessing the camera:', error);
            });
        }else{
            addAlert(t('spend.cameraNotSuport'), 'error', '', '');
        }
    };

    // Stop the video stream when the component unmounts
    const stopCamera = () => {
        const video = videoRef.current;
        if (video && video.srcObject) {
            const stream = video.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(track => {
                try {
                    track.stop();
                } catch (error) {
                    console.error('Error stopping track:', error);
                }
            });
            video.srcObject = null; // Reset video source object
        } else {
            console.warn('No video element or source object found.');
        }
    };

    const clearMerchant = () => {
        setSelectedMerchant(null);
        setActiveStep(0);
        startCamera();
        history.push(`/spend`);
    }

    useEffect(() => {
        const handleNavigation = () => {
            stopCamera();
        };

        window.addEventListener('camera', handleNavigation);

        return () => {
            window.removeEventListener('camera', handleNavigation);
        };
    }, []);

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (state?.openCamera) {
                    startCamera();
                }
                break;
            case 1:
                stopCamera();
                isMountedRef.current = true;
                setLoading(true);

                if (accessToken) {
                    getUrl('wallets/balance').then(response => {
                        if (response.status && isMountedRef.current) {
                            dispatch(storeWalletBalance(response.data));
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    })
                }
                setLoading(false);
                return () => { isMountedRef.current = false };
            default:
                stopCamera();
                break;
        }
    }, [activeStep, state?.openCamera]);

    useEffect(() => {
        if (!isNil(mid)) {
            const decodedId = atob(mid);
            setState(prevState => ({
                ...prevState,
                openCamera: false,
            }));
            stopCamera();
            getMerchantInfo(decodedId);
        }
    }, [mid]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        let wallet = _.find(walletBalance, { 'id': 4 });
        let decimalNeeded = 2
        if (wallet) {
            decimalNeeded = wallet?.decimal;
        }
        switch (name) {
            case 'amount':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to wallet decimal
                const parts = value.split('.');
                if (parts[1] && parts[1].length > decimalNeeded) {
                    value = `${parts[0]}.${parts[1].slice(0, decimalNeeded)}`;
                }
                setState({ ...state, [name]: value });
                break;

            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const makePayment = () =>{
        setLoading(true);
        setInputErrors({});
        let params = {
            amount: state?.amount,
            security_password: state?.securityPassword,
            merchant_id: selectedMerchant?.id,
            wallet_id: state?.wallet_id,
        }

        postUrl(`/spend`, params).then(response => {
            setLoading(false);
            let { status, message, data , errors } = response;

            if (status) {
                setActiveStep(2);
                setResult(data);
                addAlert(message || t('payment.success'), 'success', '', '');
                
            } else {
                addAlert(message || t('general.defaultErrorDescription'), 'error', '', '');
                setInputErrors(errors);
            }

        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            case 2:
                return stepThree();
            default:
                return null;
        }
    }

    const triggerFileInput = () => {
        fileInputRef.current.click(); // Programmatically trigger the hidden input
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const image = new Image();
            image.src = e.target.result;
    
            image.onload = function () {
              // Create a canvas to extract the QR code
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
              context.drawImage(image, 0, 0, image.width, image.height);
    
              const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
              const code = jsQR(imageData.data, canvas.width, canvas.height);
    
              if (code) {
                let splitText = (code.data).split("?m=");
                if (_.size(splitText) > 0) {
                    let param = splitText[1];
                    if (window.location.href === splitText[0]) {
                        let decodedId = atob(param);
                        if (decodedId > 0) {
                            getMerchantInfo(decodedId);
                            setState({ ...state, openCamera: false });
                            stopCamera();
                        }
                    } else {
                        addAlert(t('spend.invalidLink'), 'error','','');
                    }
                }
              } else {
                addAlert(t('spend.noQRCodeFound'), 'error','','');
              }
            };
          };
          reader.readAsDataURL(file);
        }
      };

      useEffect(() => {
        checkCameraPermission();
      }, []);

      const checkCameraPermission = async () => {
        try {
          const permission = await navigator.permissions.query({ name: 'camera' });
          setPermissionStatus(permission.state);

          if (permission.state === 'granted') {
            startCamera();
          }
    
          // Listen for permission changes
          permission.onchange = () => {
            setPermissionStatus(permission.state);
            if (permission.state === 'granted') {
              startCamera();
            }
          };   
    
        } catch (error) {
          // If an error occurs, permission is denied or not granted yet
          if (error.name === 'NotAllowedError') {
            setPermissionStatus('denied');
          } else {
            setPermissionStatus('error');
          }
        }
      };

    const walletBalanceDisplay = () => {
        let displayWallet = [];
        _.map(walletBalance, wbalance => {
            if ((parseInt(wbalance.id) === parseInt(state.wallet_id)) || (extraTransferWallet?.[state.wallet_id] ? (_.includes(extraTransferWallet?.[state.wallet_id], parseInt(wbalance.id))) : false)) {
                displayWallet.push(wbalance);
            }
        })
        return (
            <div className='flex-l-m'>
                {t('spend.availableBalance') + " [ "}
                {
                    _.map(displayWallet, (wallet, key) => {
                        let wbalance = wallet?.balance;
                        if (wallet.allow_merchant) {
                            if (_.size(wallet.wallet_details) > 0) {
                                let findWallet = _.find(wallet.wallet_details, function ($wd) { return $wd.merchant_company_id == selectedMerchant?.id; });
                                wbalance = findWallet?.balance;
                            } else {
                                wbalance = 0;
                            }
                        }
                        return (
                            <div className='flex-l-m' key={key}>
                                {key > 0 && <p style={{ marginRight: 5 }}>, </p>}
                                <p style={{ marginRight: 5 }}>{stringToLang(wallet?.name) + ": "}</p>
                                <b><NumberFormat value={`${wbalance || 0} `} decimalScale={wallet?.decimal} displayType={'text'} thousandSeparator={true} /></b>
                            </div>
                        )
                    })
                }
                {" ] "}
            </div>
        )
    }

    const stepOne = () => {
        return (
            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                {
                    permissionStatus === 'granted' &&
                    <div className='flex-c-m s-full pos-relative'>
                        <video ref={videoRef} style={{ borderRadius: 15 }} autoPlay playsInline muted className='s-full' />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                }
                <div className='s-full'>
                    <input type="file" accept="image/*" ref={fileInputRef} onChange={handleImageUpload} style={{ display: 'none' }} />
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={triggerFileInput} >{t('button.uploadImage')}</Button>
                </div>
            </div>
        )
    };

    const stepTwo = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        {/* <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.gray.main }}>{t('spend.merchantName')}</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <div className='flex-sb-m' style={{ backgroundColor: theme.palette.white.main , boxShadow: '-3px -3px 4px 0px #FFFFFF inset,2px 2px 3px 0px #0000001A inset', borderRadius: 10, padding: 20 }}>
                                <div style={{ width: 70, height: 70 }} className='flex-c-m'>
                                    <FiHome style={{ fontSize: 30 }} />
                                </div>
                                <div className='w-full p-l-20' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ color: theme.palette.gray.main, fontWeight: 'bold', wordBreak: 'break-all' }}>{selectedMerchant[`shop_name_${i18n.language}`] ? selectedMerchant[`shop_name_${i18n.language}`] : selectedMerchant?.shop_name_en}</Typography>
                                    <Typography style={{ color: theme.palette.gray.dtext, fontStyle: 'italic', wordBreak: 'break-all' }}>{selectedMerchant?.email || ""}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', minHeight: 70, cursor: 'pointer' }} onClick={clearMerchant}>
                                    <div className='flex-c-m' style={{ backgroundColor: theme.palette.primary.main, boxShadow: '2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026', padding: 7, borderRadius: 5, top: 0 }}><FiEdit style={{ color: 'white' }} /></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='fs-textfield'>{t('spend.spendWallet')}</p>
                            <Select
                                native
                                fullWidth
                                variant="outlined"
                                value={state.wallet_id}
                                onChange={handleChange}
                                inputProps={{ name: 'wallet_id' }}
                                error={inputErrors?.wallet_id ? true : false}
                            >
                                <option value="" disabled>{t('general.pleaseSelect')}</option>
                                {
                                    (_.size(walletList) > 0) &&
                                    _.map(walletList, item => {
                                        if (_.includes(selectedWallet, item.id)) {
                                            return (
                                                <option key={item.id} value={item.id}>{stringToLang(item?.name)}</option>
                                            )
                                        }
                                    })
                                }
                            </Select>
                            {state.wallet_id && walletBalanceDisplay()}
                            {inputErrors?.wallet_id && <Typography color="error" variant='caption'>{inputErrors?.wallet_id}</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <p className='fs-textfield'>{t('spend.amount')}</p>
                            <TextField
                                error={inputErrors?.amount ? true : false}
                                helperText={inputErrors?.amount}
                                fullWidth
                                variant="filled"
                                size="small"
                                type="number"
                                name="amount"
                                onChange={handleChange}
                                value={state.amount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p className='fs-textfield'>{t('spend.securityPassword')}</p>
                            <TextField
                                error={inputErrors?.security_password ? true : false}
                                helperText={inputErrors?.security_password}
                                fullWidth
                                variant="filled"
                                size="small"
                                autoComplete='new-password'
                                type={state.showSecurityPassword ? 'text' : 'password'}
                                name="securityPassword"
                                onChange={handleChange}
                                value={state.securityPassword}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                        {
                            inputErrors && _.size(inputErrors) > 0 &&
                            _.map(inputErrors , (data, dataIndex)=>{
                                if(!_.includes(['security_password', 'amount', 'wallet_id'], dataIndex)){
                                    return (
                                        <Grid key={dataIndex} item xs={12} style={{ paddingLeft: 15 }} >
                                            <Typography color="error" variant='caption' >{data}</Typography>
                                        </Grid>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        }
                        <Grid item xs={12}>
                        <div className='btn-theme w-full translateY' onClick={makePayment}>
                            <p className='fs-button'>{t('button.payNow')}</p>
                        </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepThree = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m' style={{ textAlign:'center'}}>
                        <Grid item xs={12} className='flex-c-m'>
                            <div style={{ width: 70, height: 70 , display:'contents' }} >
                                <FiHome style={{ fontSize: 30 }} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ color:theme.palette.gray.main , fontWeight:'bold', wordBreak:'break-all' }}>{selectedMerchant[`shop_name_${i18n.language}`] ? selectedMerchant[`shop_name_${i18n.language}`] : selectedMerchant?.shop_name_en}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 5}}>
                            <Typography style={{ color:theme.palette.gray.main , fontStyle:'italic' , wordBreak:'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {console.log('data',result)}
                            <div className='flex-c-m w-full' style={{ backgroundColor: theme.palette.white.main , boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset' , padding:15, borderRadius:10 , flexDirection:'column', flexWrap:'wrap' }}>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color:theme.palette.gray.main }}>{t('spend.dateTime')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.dtext , fontStyle:'italic' }}>{result?.created_at || '-'}</Typography>
                                </div>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main }}>{t('spend.transactionId')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.dtext , fontStyle:'italic', wordBreak:'break-all' , textAlign:'end', width:'45%' }}>{result?.id || '-'}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-c-m w-full' style={{ backgroundColor: theme.palette.white.main, boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset', padding: 15, borderRadius: 10, flexDirection: 'column' }}>
                                <div className='w-full' style={{ textAlign: 'left' }}>
                                    <Typography style={{ color: theme.palette.gray.lightTitle, fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                                </div>
                                {
                                    _.size(result?.transaction_details) > 0 ?
                                        <>
                                            {
                                                _.map(result?.transaction_details , (data , dataIndex)=>{
                                                    if (_.includes(selectedWallet, parseInt(data?.pay_code))) {
                                                        let wallet = _.find(walletList, { 'id': parseInt(data?.pay_code) });
                                                        return (
                                                            <div className='flex-sb-m w-full p-tb-5' key={dataIndex}>
                                                                <Typography variant="subtitle1" style={{ color: theme.palette.gray.main }}>{stringToLang(data?.wallet_name)}</Typography>
                                                                <Typography variant="subtitle1" style={{ color: theme.palette.gray.dtext }}><NumberFormat value={`${data?.amount || 0} `} decimalScale={wallet?.decimal} displayType={'text'} thousandSeparator={true} /></Typography>
                                                            </div>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            }
                                        </>
                                        :
                                        <div className='flex-c-m p-t-10'>
                                            <Typography>{t('wallet.noWallet')}</Typography>
                                        </div>
                                }
                                <div className='flex-sb-m w-full m-t-10'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('spend.totalAmount')}</Typography>
                                    <b><NumberFormat value={result?.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} /></b>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <div className='btn-outlined-theme w-full translateY' onClick={()=> history.push(`/wallet-home`)}>
                                    <p className='fs-button'>{t('button.history')}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <div className='btn-theme w-full translateY' onClick={()=> window.location.reload()}>
                                    <p className='fs-button'>{t('button.newPayment')}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'><b>{t('title.spend')}</b></p>
                    <p className='fs-subheader cl-label'>{t('settings.subTitle.spend')}</p>
                </div>
            </div>
            <Container className='m-t-40'>
                <div className='flex-c-m'>
                    <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.primary.main, fontWeight: 700, fontSize: 25 }} >
                        <b>
                            {steps[activeStep]}
                        </b>
                    </Typography>
                </div>
                {stepperDisplay(activeStep)}
            </Container>
        </div>
    )
}